
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'

@Options({
  components: {
    PagePlugin,
    CustomDialog
  }
})
export default class change_audit extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  tableData_all_data = []
  tableData_total = 0
  tableData_search_data = {
    user_name: '',
    name: ''
  }
  rule_tableData_search_data = {}
  tableData_detail_dialog = {
    open: false,
    title: '详情'
  }
  tableData_detail_data = {
    code: '',
    name: '',
    party_a: '',
    party_b: '',
    party_a_proportion: 0,
    party_b_proportion: 0,
    sum: 0,
    assignments: [] as any[],
    type: 0,
    materials: [] as any[]
  }
  tableData_edit_dialog = {
    open: false,
    title: '审批'
  }
  tableData_edit_data = {
    id: 0,
    status_: 0,
    reason: ''
  }
  rule_tableData_edit_data = {
    status_: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }
  tableData_edit1_dialog = {
    open: false,
    title: '申请异常审议'
  }
  tableData_edit1_data = {
    id: 0,
    res_change_id: 0
  }
  rule_tableData_edit1_data = {}
  tableData_edit2_dialog = {
    open: false,
    title: '申请异常复审'
  }
  tableData_edit2_data = {
    id: 0,
    res_change_id: 0
  }
  rule_tableData_edit2_data = {}
  audit_status_234 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派产研院专员'],
    [6, '待产研院审核（转让许可）'],
    [7, '待指派权属委专员'],
    [8, '待权属委审核（转让许可）'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示完成'],
    [11, '待指派校章专员'],
    [12, '待盖章'],
    [13, '已通过'],
    [14, '待指派资产公司专员'],
    [15, '待资产公司审核（作价投资）'],
    [16, '待指派权属委专员'],
    [17, '待权属委审核（作价投资）'],
    [18, '待指派资产公司专员'],
    [19, '待指派异常审议人'],
    [20, '待异常审议'],
    [21, '待指派异常复审人'],
    [22, '待异常审议复审']
  ])
  current_node_209 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派产研院专员'],
    [6, '待产研院审核（转让许可）'],
    [7, '待指派权属委专员'],
    [8, '待权属委审核（转让许可）'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示完成'],
    [11, '待指派校章专员'],
    [12, '待盖章'],
    [13, '已通过'],
    [14, ' 待指派资产公司专员'],
    [15, '待资产公司审核（作价投资）'],
    [16, '待指派权属委专员'],
    [17, '待权属委审核（作价投资）'],
    [18, '待指派资产公司专员'],
    [19, '待指派异常审议人'],
    [20, '待异常审议'],
    [21, '待指派异常复审人'],
    [22, '待异常审议复审']
  ])
  status_210 = new Map<any, string>([
    [1, '待审核'],
    [2, '通过'],
    [3, '不通过']
  ])
  audit_status_214 = new Map<any, string>([])
  status__219 = new Map<any, string>([
    [2, '通过'],
    [3, '不通过']
  ])
  type_227 = new Map<any, string>([
    [1, '转让许可'],
    [2, '作价投资']
  ])
  tableData_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.tableData_search_data.user_name,
      name: this.tableData_search_data.name,
      status: [1, 2, 3],
      type: null
    }
    Api.http_change_audittableData0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableData_all_data = res.data.data
        this.tableData_total = res.data.total
      }
    })
  }
  tableData_detail_init(row) {
    Api.http_change_audittableDataget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableData_detail_data.code = res.data.code
        this.tableData_detail_data.name = res.data.name
        this.tableData_detail_data.party_a = res.data.party_a
        this.tableData_detail_data.party_b = res.data.party_b
        this.tableData_detail_data.party_a_proportion
          = res.data.party_a_proportion
        this.tableData_detail_data.party_b_proportion
          = res.data.party_b_proportion
        this.tableData_detail_data.sum = res.data.sum
        this.tableData_detail_data.assignments = res.data.assignments
        this.tableData_detail_data.type = res.data.type
        this.tableData_detail_data.materials = res.data.materials
        this.tableData_detail_dialog.open = true
        this.setDialogName(this.tableData_detail_dialog.title)
      }
    })
  }
  tableData_detail_cancle() {
    this.tableData_detail_dialog.open = false
    this.removeDialogName()
  }
  tableData_detail_ok() {
    this.tableData_detail_dialog.open = false
    this.removeDialogName()
  }
  tableData_edit_init(row) {
    // const createTime = new Date(row.created_at).getTime() + 1000 * 60 * 60 * 24
    // const currentTime = Date.now()

    // if (row.audit_status === 10 && Math.ceil((currentTime - createTime) / (1000 * 60 * 60 * 24)) < row.public_time) {
    //   this.$message.error('公示期内不允许审核')
    //   return
    // }
    Api.http_change_audittableDataget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableData_edit_data.id = row.id
        this.tableData_edit_data.status_ = res.data.status_
        this.tableData_edit_data.reason = res.data.reason
        this.tableData_edit_dialog.open = true
      }
    })
  }
  tableData_edit_cancle() {
    this.tableData_edit_dialog.open = false
  }
  tableData_edit_ok() {
    this.$refs.ref_tableData_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableData_edit_data.id,
          status_: this.tableData_edit_data.status_,
          reason: this.tableData_edit_data.reason
        }
        Api.http_change_audittableData5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableData_edit_dialog.open = false
            this.tableData_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableData_edit1_init(row) {
    Api.http_change_audittableDataget6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableData_edit1_data.id = row.id
        this.tableData_edit1_data.res_change_id = res.data.res_change_id
        this.tableData_edit1_dialog.open = true
      }
    })
  }
  tableData_edit1_cancle() {
    this.tableData_edit1_dialog.open = false
  }
  tableData_edit1_ok() {
    this.$refs.ref_tableData_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableData_edit1_data.id,
          res_change_id: this.tableData_edit1_data.res_change_id
        }
        Api.http_change_audittableData6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableData_edit1_dialog.open = false
            this.tableData_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableData_edit2_init(row) {
    Api.http_change_audittableDataget7({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableData_edit2_data.id = row.id
        this.tableData_edit2_data.res_change_id = res.data.res_change_id
        this.tableData_edit2_dialog.open = true
      }
    })
  }
  tableData_edit2_cancle() {
    this.tableData_edit2_dialog.open = false
  }
  tableData_edit2_ok() {
    this.$refs.ref_tableData_edit2_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableData_edit2_data.id,
          res_change_id: this.tableData_edit2_data.res_change_id
        }
        Api.http_change_audittableData7(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableData_edit2_dialog.open = false
            this.tableData_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableData_init() {
    this.tableData_get_all_data(0, 10)
  }
  created() {
    this.tableData_init()
  }
}
